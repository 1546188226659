<template>
    <div class="VideoDetail">
        <TopNav />
        <div class="main">
            <div class="section1">
                <div class="VideoBox">
                    <div class="VideoContent">
                        <div class="TopBar">教学教程 > 入门篇 > 如何使用项目管理模块</div>
                        <div class="Video">
                            <!-- <video src="../assets/Video/xzg_74856.mp4" controls></video> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="section2">
                <div class="DetailsBox">
                    <div class="DetailsContent">
                        <div class="Detail">
                            <div class="DetailTopPanel">
                                <div class="TopBox" @click="Select = 0"
                                    :style="Select === 0 ? 'font-weight: 500;' : 'font-weight: 400;'">
                                    <div class="Toptilte">课程详情</div>
                                    <hr v-if="Select === 0">
                                </div>
                                <div class="TopBox" @click="Select = 1"
                                    :style="Select === 1 ? 'font-weight: 500;' : 'font-weight: 400;'">
                                    <div class="Toptilte">评价(213)</div>
                                    <hr v-if="Select === 1">
                                </div>
                            </div>
                            <div class="DetailMain" v-if="Select === 0">
                                <div class="Learngoal">
                                    <div class="LearngoalTitle">学习目标</div>
                                    <ul>
                                        <li>快速掌握如何使用千龙办公协同</li>
                                        <li>掌握某些知识点</li>
                                        <li>学会某些技巧（或思路）</li>
                                    </ul>
                                </div>
                                <div class="DetailPhoto">
                                    <div class="DetailTitle">课程详情</div>
                                    <div class="DetailImg" :style="zankai ? 'height: 650px;' : 'height: 100%'">
                                        <img src="../assets/picture/DetailImg.png">
                                        <img src="../assets/picture/Photozankai.png" class="Photozankai"
                                            v-show="zankai">
                                    </div>
                                </div>
                                <div class="zankai">
                                    <span @click="zankai = false" v-show="zankai">查看全部</span>
                                    <img src="../assets/icon/zankai.png" @click="zankai = 0" v-show="zankai">
                                </div>
                            </div>
                            <div class="DetailMain2" v-if="Select === 1">
                                <div class="LearngoalTitle">用户评价</div>
                                <div class="selectPanel">
                                    <div class="selectBox" v-for="(item, index) in commentsList "
                                        @click="commentsSelect = index"
                                        :style="commentsSelect === index ? 'font-weight: 500; background: rgba(224, 236, 255, 1); color: rgba(54, 94, 255, 1);' : 'font-weight: 400;'">
                                        {{ item.commentsKind }}(12)</div>
                                </div>
                                <!-- 评论 -->
                                <div class="commentBox">
                                    <div class="commentBoxLeft">
                                        <img src="../assets/picture/avatar.png">
                                    </div>
                                    <div class="commentBoxRight">
                                        <div class="UserId">
                                            S**5
                                            <span class="report">举报</span>
                                        </div>
                                        <div class="UserMsg">
                                            1个月前 | 已上课4时24分时评论
                                            <img src="../assets/picture/commentgroup.png">
                                        </div>
                                        <div class="CommentContent">
                                            老师讲课思维严谨，耐心，答疑也很耐心仔细。每天都有跟现场实际接轨的小灶，老师备课仔细认真，用心上课。我很受用，苦于有时时间少，自己动手实际操作装配式项目少。相信以后跟着峰源，把基础考试过后，我会边听回放边实际操作项目，不久都会成为高手。
                                        </div>
                                    </div>
                                </div>
                                <!-- 评论 -->
                                <!-- 评论 -->
                                <div class="commentBox">
                                    <div class="commentBoxLeft">
                                        <img src="../assets/picture/avatar.png">
                                    </div>
                                    <div class="commentBoxRight">
                                        <div class="UserId">
                                            S**5
                                            <span class="report">举报</span>
                                        </div>
                                        <div class="UserMsg">
                                            1个月前 | 已上课4时24分时评论
                                            <img src="../assets/picture/commentgroup.png">
                                        </div>
                                        <div class="CommentContent">
                                            老师讲课思维严谨，耐心，答疑也很耐心仔细。每天都有跟现场实际接轨的小灶，老师备课仔细认真，用心上课。我很受用，苦于有时时间少，自己动手实际操作装配式项目少。相信以后跟着峰源，把基础考试过后，我会边听回放边实际操作项目，不久都会成为高手。
                                        </div>
                                    </div>
                                </div>
                                <!-- 评论 -->
                                <div class="paginationBox">
                                    <el-pagination background layout="prev, pager, next" :total="100"
                                        style="float: right;">
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                        <div class="relevanceVideo">
                            <div class="relevanceTitle">相关课程</div>
                            <div class="CourseBox">
                                <div class="CourseLeft">
                                    <img src="../assets/picture/Course.png">
                                </div>
                                <div class="CourseRight">
                                    <div class="CourseName">学习如何使用研发管理</div>
                                    <div class="PeopleTotal">156人已学</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '../components/Footer.vue';
import TopNav from '../components/TopNav.vue';
import ElementUI from 'element-ui';
import Vue from 'vue';


Vue.use(ElementUI)


export default {
    data() {
        return {
            Select: 0, zankai: true, commentsSelect: 0,
            commentsList: [
                {
                    commentsKind: '全部'
                },
                {
                    commentsKind: '好评'
                },
                {
                    commentsKind: '中评'
                },
                {
                    commentsKind: '差评'
                },
            ]
        }
    },
    components: { Footer, TopNav }
}
</script>

<style lang="less" scoped>
.VideoDetail {
    width: 100%;
    height: 100%;
}

.section1 {
    display: flex;
    justify-content: center;

    >.VideoBox {
        max-width: 1920px;
        width: 100%;
        display: flex;
        justify-content: center;

        >.VideoContent {
            width: 1200px;

            >.TopBar {
                height: 60px;
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 400;
                color: rgba(78, 89, 105, 1);
            }

            >.Video {
                height: 540px;

                >video {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.section2 {
    display: flex;
    justify-content: center;

    >.DetailsBox {
        max-width: 1920px;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: rgba(247, 249, 255, 1);

        >.DetailsContent {
            margin-top: 60px;
            display: flex;
            width: 1200px;
            margin-bottom: 80px;
            border-radius: 8px;
            box-shadow: 0px 3px 8px 2px rgba(217, 217, 217, 0.2);

            >.Detail {
                width: 820px;
                background-color: rgba(255, 255, 255, 1);

                >.DetailTopPanel {
                    width: 100%;
                    display: flex;

                    >.TopBox {
                        display: flex;
                        justify-content: center;
                        width: 103px;
                        flex-wrap: wrap;

                        >.Toptilte {
                            width: 103px;
                            margin-top: 20px;
                            height: 30px;
                            text-align: center;
                            cursor: pointer;
                        }

                        >hr {
                            width: 49px;
                            height: 2px;
                            border: 0px;
                            background-color: rgba(153, 174, 255, 1);
                        }
                    }
                }

                >.DetailMain {
                    >.Learngoal {

                        >.LearngoalTitle {
                            margin: 40px 0 12px 20px;
                            font-weight: 700;
                            font-size: 20px;
                        }

                        >ul {
                            >li {
                                font-size: 14px;
                                list-style: inside;
                                font-weight: 400;
                                color: rgba(78, 89, 105, 1);
                                text-indent: 20px;
                            }
                        }
                    }

                    >.DetailPhoto {
                        >.DetailTitle {
                            margin: 40px 0 12px 20px;
                            font-weight: 700;
                            font-size: 20px;
                        }

                        >.DetailImg {
                            overflow: hidden;
                            position: relative;

                            >img {
                                margin-left: 20px;
                            }

                            >.Photozankai {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                            }
                        }
                    }

                    >.zankai {
                        height: 70px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        >span {
                            color: rgba(78, 89, 105, 1);
                            font-size: 14px;
                            font-weight: 400;
                            cursor: pointer;
                        }

                        >img {
                            cursor: pointer;
                            margin-left: 7px;
                        }
                    }
                }

                >.DetailMain2 {
                    padding: 0 20px;

                    >.LearngoalTitle {
                        margin: 40px 0 12px 0;
                        font-weight: 700;
                        font-size: 20px;
                    }

                    >.selectPanel {
                        height: 35px;
                        display: flex;

                        >.selectBox {
                            height: 34px;
                            width: 82px;
                            display: flex;
                            cursor: pointer;
                            justify-content: center;
                            align-items: center;
                            border-radius: 6px;
                        }

                        >.selectBox:not(:first-child) {
                            margin-left: 20px;
                        }
                    }

                    >.commentBox {
                        margin-top: 17px;
                        display: flex;

                        >.commentBoxLeft {
                            width: 40px;
                        }

                        >.commentBoxRight {
                            margin-left: 12px;

                            >.UserId {
                                font-size: 14px;
                                font-weight: 500;
                                color: rgba(29, 33, 41, 1);

                                >.report {
                                    color: rgba(78, 89, 105, 1);
                                    font-size: 14px;
                                    font-weight: 400;
                                    cursor: pointer;
                                    float: right;
                                }
                            }

                            >.UserMsg {
                                color: rgba(78, 89, 105, 1);
                                font-size: 14px;
                                font-weight: 400;

                                >img {
                                    margin-left: 8px;
                                }
                            }

                            >.CommentContent {
                                margin-top: 8px;
                                text-align: justify;
                                border-bottom: 1px solid rgba(229, 230, 235, 0.6);
                                padding-bottom: 12px;
                                font-size: 14px;
                                font-weight: 400;
                            }

                        }
                    }

                    >.paginationBox {
                        margin: 40px 0;
                        height: 32px;
                    }

                }
            }

            >.relevanceVideo {
                width: 360px;
                background-color: #ffffff;
                margin-left: 20px;
                box-shadow: 0px 3px 8px 2px rgba(217, 217, 217, 0.2);
                border-radius: 8px;

                >.relevanceTitle {
                    margin: 20px;
                    font-weight: 700;
                    font-size: 20px;
                }

                >.CourseBox {
                    display: flex;

                    >.CourseLeft {
                        margin-left: 20PX;
                    }

                    >.CourseRight {
                        margin-left: 10px;

                        >.CourseName {
                            font-size: 14px;
                            font-weight: 500;
                        }

                        >.PeopleTotal {
                            font-size: 12px;
                            font-weight: 400;
                            color: rgba(134, 144, 156, 1);
                            margin-top: 40px;
                        }
                    }
                }
            }
        }
    }
}
</style>