//导航栏入口

export const BasicFeatures = [
    [
        {
            routerTitle: '自定义工作台',
            routerDescribe: '自由配置工作台，让工作更高效',
            path: '/CustomWorkbench'
        },
        {
            routerTitle: '日程',
            routerDescribe: '一键轻松创建日程，时间规划更方便更高效',
            path: '/'
        },
        {
            routerTitle: '日志中心',
            routerDescribe: '高效工作汇报，激发组织活力',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '待办工作台',
            routerDescribe: '一站式待办管理中心，更高效地规划和处理大小事',
            path: '/PendingWorkbench'
        },
        {
            routerTitle: '我的关注',
            routerDescribe: '一键关注，确保事情件件落实',
            path: '/'
        },
        {
            routerTitle: '考勤打卡',
            routerDescribe: '精准定位安全守护，考勤统计实时查看',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '视图编辑器',
            routerDescribe: '更加科学的视图编辑器，搜索、筛选更便捷',
            path: '/ViewsEditors'
        },
        {
            routerTitle: '我的业绩',
            routerDescribe: '科学的业绩管理，激发员工积极性',
            path: '/'
        }
    ]
]
export const ProjectManage = [
    [
        {
            routerTitle: '项目清单',
            routerDescribe: '快速检索项目，了解项目情况',
            path: '/'
        },
        {
            routerTitle: '任务清单',
            routerDescribe: '检索及编制项目任务',
            path: '/'
        },
        {
            routerTitle: '项目日志',
            routerDescribe: '快速查看项目团队成员每天计划及进展',
            path: '/'
        },
        {
            routerTitle: '项目组织',
            routerDescribe: '方便项目负责人对项目成员的管理',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '进展记录',
            routerDescribe: '了解项目团队的进展情况，实时掌握项目进展',
            path: '/'
        },
        {
            routerTitle: '项目计划',
            routerDescribe: '利用甘特图等各种表现方式快速检索及编制项目计划',
            path: '/'
        },
        {
            routerTitle: '工时管理',
            routerDescribe: '方便管理人员了解项目工时使用情况',
            path: '/'
        },
        {
            routerTitle: '项目账户',
            routerDescribe: '支持业绩申请、业绩流水检索等',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '跟进记录',
            routerDescribe: '快速检索跟项目相关的各类跟进记录，例如客户沟通等',
            path: '/'
        },
        {
            routerTitle: '商务',
            routerDescribe: '随时了解项目商务情况，例如项目合同、联系人信息等',
            path: '/'
        },
        {
            routerTitle: '项目动态',
            routerDescribe: '快速检索项目的各类动态变化，例如项目变更等',
            path: '/'
        },
        {
            routerTitle: '项目聊天群',
            routerDescribe: '以项目为维度建立聊天群，便于团队的沟通',
            path: '/'
        },
    ]
]
export const ResearchManage = [
    [
        {
            routerTitle: '研发清单',
            routerDescribe: '快速检索研发项目及产品',
            path: '/'
        },
        {
            routerTitle: '任务清单',
            routerDescribe: '检索及编制研发任务',
            path: '/'
        },
        {
            routerTitle: '研发日志',
            routerDescribe: '快速查看研发团队成员每天计划及进展',
            path: '/'
        },
        {
            routerTitle: '研发组织',
            routerDescribe: '方便项目负责人对研发成员的管理',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '进展记录',
            routerDescribe: '了解研发团队的进展情况，实时掌握研发进展',
            path: '/'
        },
        {
            routerTitle: '项目计划',
            routerDescribe: '利用甘特图等各种表现方式快速检索及编制研发计划及功能点管理',
            path: '/'
        },
        {
            routerTitle: '工时管理',
            routerDescribe: '方便管理人员了解研发工时使用情况',
            path: '/'
        },
        {
            routerTitle: '项目账户',
            routerDescribe: '支持业绩申请、业绩流水检索等',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '跟进记录',
            routerDescribe: '快速检索跟研发相关的各类跟进记录，例如客户沟通等',
            path: '/'
        },
        {
            routerTitle: '商务',
            routerDescribe: '随时了解研发商务情况，例如研发合同、联系人信息等',
            path: '/'
        },
        {
            routerTitle: '研发动态',
            routerDescribe: '快速检索研发的各类动态变化，例如研发变更等',
            path: '/'
        },
        {
            routerTitle: '研发聊天群',
            routerDescribe: '以研发项目或研发产品为维度建立聊天群，便于团队的沟通',
            path: '/'
        },
    ]
]
export const CRMManage = [
    [
        {
            routerTitle: '活跃客户',
            routerDescribe: '快速检索活跃中的客户，方便跟进',
            path: '/'
        },
        {
            routerTitle: '活跃商机',
            routerDescribe: '快速检索活跃中的商机，方便跟进',
            path: '/'
        },
        {
            routerTitle: '正常应收',
            routerDescribe: '正常应收的跟进及回款处理',
            path: '/'
        },
        {
            routerTitle: '预签合同',
            routerDescribe: '合同编制、审批',
            path: '/'
        },
        {
            routerTitle: '跟进管理',
            routerDescribe: '客户、商机、合同、回款跟进记录的综合管理',
            path: '/'
        },
    ],
    [
        {
            routerTitle: '客户公海',
            routerDescribe: '管理长期无法跟进或无负责人的客户',
            path: '/'
        },
        {
            routerTitle: '商机公海',
            routerDescribe: '管理长期无法跟进或关闭的商机',
            path: '/'
        },
        {
            routerTitle: '呆账管理',
            routerDescribe: '对归于呆账的应收进行管理',
            path: '/'
        },
        {
            routerTitle: '履约合同',
            routerDescribe: '针对履约中的合同进行管理',
            path: '/'
        },
        {
            routerTitle: '采购合同',
            routerDescribe: '采购合同的编制、审批及管理',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '全部客户',
            routerDescribe: '快速检索、管理所有客户',
            path: '/'
        },
        {
            routerTitle: '成交商机',
            routerDescribe: '快速检索、管理已经成交的商机',
            path: '/'
        },
        {
            routerTitle: '已收回款',
            routerDescribe: '快速检索、管理已收回款记录',
            path: '/'
        },
        {
            routerTitle: '归档合同',
            routerDescribe: '快速检索已经归档合同',
            path: '/'
        },
        {
            routerTitle: '应付管理',
            routerDescribe: '应付账款的跟进及管理',
            path: '/'
        },
    ]
]
export const OrganizationManage = [
    [
        {
            routerTitle: '组织配置',
            routerDescribe: '职能组织及非职能组织创建、删除',
            path: '/'
        },
        {
            routerTitle: '成员绩效',
            routerDescribe: '组织成员的绩效考核',
            path: '/'
        },
        {
            routerTitle: '事项中心',
            routerDescribe: '快速检索组织成员所负责的事项',
            path: '/'
        },
        {
            routerTitle: '跟进记录',
            routerDescribe: '快速检索组织成员的跟进记录，例如客户联系',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '组织成员',
            routerDescribe: '组织成员的添加、移除等管理',
            path: '/'
        },
        {
            routerTitle: '成员工时',
            routerDescribe: '快速统计成员工时情况',
            path: '/'
        },
        {
            routerTitle: '进度计划',
            routerDescribe: '编制及检索组织成员的进度计划',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '组织账户',
            routerDescribe: '组织业绩、奖金等账户管理',
            path: '/'
        },
        {
            routerTitle: '成员日志',
            routerDescribe: '了解组织成员每天工作计划及进展',
            path: '/'
        },
        {
            routerTitle: '进展记录',
            routerDescribe: '快速检索组织成员的工作进展',
            path: '/'
        },
    ]
]
export const OAApproval = [
    [
        {
            routerTitle: '发起审批',
            routerDescribe: '提供审批单的发起',
            path: '/'
        },
        {
            routerTitle: '已发起',
            routerDescribe: '快速检索我发起的审批记录',
            path: '/'
        },
        {
            routerTitle: '审批单配置',
            routerDescribe: '各类审批单及分类的配置',
            path: '/'
        },
        {
            routerTitle: '自定义表单',
            routerDescribe: '采用低代码技术，支持所见即所得的表单编辑',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '待处理',
            routerDescribe: '快速处理需要我处理的审批',
            path: '/'
        },
        {
            routerTitle: '抄送我的',
            routerDescribe: '快速检索抄送给我的审批记录',
            path: '/'
        },
        {
            routerTitle: '工作流配置',
            routerDescribe: '支持图形化工作流配置，并按需求进行分类',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '已处理',
            routerDescribe: '快速检索我已经处理的审批记录',
            path: '/'
        },
        {
            routerTitle: '审批代办',
            routerDescribe: '支持设置代办人，及查看代办历史',
            path: '/'
        },
        {
            routerTitle: '工作流引擎',
            routerDescribe: '实现对流程实例的解释及处理',
            path: '/'
        },
    ]
]
export const ConferenceSystem = [
    [
        {
            routerTitle: '快速会议',
            routerDescribe: '提供随时随地开启在线会议',
            path: '/'
        },
        {
            routerTitle: '多人共享',
            routerDescribe: '支持多人同时共享，由会议主持人确定展示的共享',
            path: '/'
        },
        {
            routerTitle: '会议签到',
            routerDescribe: '一键发起签到，结果实时反馈，考勤状态一目了然',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '预约会议',
            routerDescribe: '支持在线会议的预约设置',
            path: '/'
        },
        {
            routerTitle: '会议管控',
            routerDescribe: '提供会议主持人对会议的管理，例如禁音等',
            path: '/'
        },
        {
            routerTitle: '会议白板',
            routerDescribe: '提供激光笔、画笔、文本、图形等多种工具辅助演示',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '节目单',
            routerDescribe: '提供手动添加或导入节目单',
            path: '/'
        },
        {
            routerTitle: '会议录制',
            routerDescribe: '提供会议云录制及本地录制多种方式，重要会议内容一键存档',
            path: '/'
        },
        {
            routerTitle: '业务整合',
            routerDescribe: '通过与业务系统整合，会议过程可以实时了解项目、研发等情况',
            path: '/'
        },
    ]
]
export const ChatSystem = [
    [
        {
            routerTitle: '即时沟通',
            routerDescribe: '即时消息，便捷沟通',
            path: '/'
        },
        {
            routerTitle: '群组管理',
            routerDescribe: '对群成员、消息的管理',
            path: '/'
        },
        {
            routerTitle: '聊天文件',
            routerDescribe: '聊天与文件协同一体化',
            path: '/'
        },
        {
            routerTitle: '业务整合',
            routerDescribe: '即开即用的场景群，将应用和群深度融合',
            path: '/'
        },
    ],
    [
        {
            routerTitle: '视频聊天',
            routerDescribe: '低延时，高清交流',
            path: '/'
        },
        {
            routerTitle: '项目群组',
            routerDescribe: '关联项目，随时了解项目进展',
            path: '/'
        },
        {
            routerTitle: '聊天历史',
            routerDescribe: '信息多端同步，不丢失',
            path: '/'
        },
        {
            routerTitle: '会议发起',
            routerDescribe: '远程协作，无缝交流',
            path: '/'
        },
    ],
    [
        {
            routerTitle: '语音聊天',
            routerDescribe: '语音聊天，沟通无障碍',
            path: '/'
        },
        {
            routerTitle: '组织群组',
            routerDescribe: '信息云端同步 数据只跟组织走',
            path: '/'
        },
        {
            routerTitle: '通讯录',
            routerDescribe: '快速找人，数据全面安全保护',
            path: '/'
        },
        {
            routerTitle: '消息通知',
            routerDescribe: '消息智能分类信息不遗漏',
            path: '/'
        },
    ]
]
export const EnterpriseCloudrives = [
    [
        {
            routerTitle: '应用空间',
            routerDescribe: '方便文件在各应用互转，协作更高效',
            path: '/'
        },
        {
            routerTitle: '空间权限',
            routerDescribe: '按角色分配权限，安全更有保障',
            path: '/'
        },
        {
            routerTitle: '微信文件备份',
            routerDescribe: '解放移动设备，文件更安全',
            path: '/'
        },
        {
            routerTitle: '版本管理',
            routerDescribe: '打造有序沉淀的企业智库，驱动企业创新增长',
            path: '/'
        },
    ],
    [
        {
            routerTitle: '企业空间',
            routerDescribe: '统一沉淀和管理企业工作成果，员工可以随时查阅',
            path: '/'
        },
        {
            routerTitle: '属性配置',
            routerDescribe: '按专业、场景贴上不同标签，查找更方便',
            path: '/'
        },
        {
            routerTitle: '文件关联',
            routerDescribe: '业务与文件协同一体化，协作更高效',
            path: '/'
        },
        {
            routerTitle: '文件权限',
            routerDescribe: '文件加密传输，支持只读、水印等多级权限设置，企业文件更安全',
            path: '/'
        },
    ],
    [
        {
            routerTitle: '个人空间',
            routerDescribe: '支持微信相册、微信文件的备份',
            path: '/'
        },
        {
            routerTitle: '上传下载',
            routerDescribe: '稳定、高速上传下载',
            path: '/'
        },
        {
            routerTitle: '文件分享',
            routerDescribe: '安全分享，随时了解分享访问历史',
            path: '/'
        }
    ]
]
export const ManageSystem = [
    [
        {
            routerTitle: '企业信息',
            routerDescribe: '支持搜索管控，企业信息更安全',
            path: '/'
        },
        {
            routerTitle: '日志中心',
            routerDescribe: '随时了解系统访问情况，企业安全更放心',
            path: '/'
        },
        {
            routerTitle: '应用管理',
            routerDescribe: '常用工具的整合',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '成员管理',
            routerDescribe: '人员信息导入统一管理，添加／邀请员工',
            path: '/'
        },
        {
            routerTitle: '模板设置',
            routerDescribe: '丰富的自定义模板，满足不同场景的需求',
            path: '/'
        },
        {
            routerTitle: '存储管理',
            routerDescribe: '设置存储回收机制，存储管理更科学',
            path: '/'
        }
    ],
    [
        {
            routerTitle: '角色权限',
            routerDescribe: '合理权限分配，安全有保障',
            path: '/'
        },
        {
            routerTitle: '业务配置',
            routerDescribe: '业务配置化，满足不同业务的需求',
            path: '/'
        }
    ]
]