<template>
    <div class="ProjectControl">
        <TopNav />
        <div class="main">
            <div class="section_1">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentfontleft">
                            <div class="contentTitle">高效实用的项目管理解决方案</div>
                            <div class="describe">高效且实用的项目管理解决方案，更有效的掌握项目进度，让项目协作权责分明、分工清晰、流程有条不紊，项目效率更高，沟通更有效。</div>
                            <div class="ButtonBox">
                                <button class="registerButton"
                                    onclick='window.open("https://saas.teamco.host:10998/saas/enterprise/#/register-index?loginTitle=Teamco&tenantVersionType=2")'>免费注册</button>
                                <button class="loginButton" style="margin-left: 18px;">预约演示</button>
                            </div>
                        </div>
                        <div class="contentfontright">
                            <img src="../assets/picture/ProjectControl1.png" class="ProjectControldImg">
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_2">
                <div class="ProjectControlPain">
                    <div class="ProjectControlPainBox">
                        <div class="ProjectControlPainTitle">项目管理常见痛点</div>
                        <div class="ProjectControlPainList">
                            <div class="ProjectControlPainItem" v-for="item in ProjectControlPainList">
                                <img :src="item.ProjectControlIcon1" class="ProjectControlIcon1">
                                <div class="ProjectControlPainItemtitle">{{ item.ProjectControlPainItemtitle }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ViewTitleBox">
                <div class="ViewTitle">项目亮点</div>
            </div>
            <div class="section_showview1">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentfontPhoto">
                            <img src="../assets/picture/head.png" class="ProjectControlheadImg">
                            <img src="../assets/picture/Carousel1-1.png">
                        </div>
                        <div class="contentfontText">
                            <div class="contentTitle">多维筛选条件组合</div>
                            <div class="describe">
                                <p>支持自定义化项目模板，可以有效提高项目管理的效率，确保质量、减少重复工作，并促进团队成员之间的理解和协作。</p>
                                <p>首先，通过预设的项目结构、任务和流程，团队可以更快地启动项目，减少在初期阶段的规划和设置时间。</p>
                                <p>其次，自定义项目模板有助于确保项目的一致性和质量。通过制定标准化的模板，我们可以确保项目在各个环节都遵循相同的最佳实践和规范，从而降低出错的可能性，并提高项目的整体质量。
                                </p>
                                <p>此外，使用自定义项目模板还可以减少重复工作。当团队开始新的项目时，他们可以利用已有的模板作为起点，而无需从头开始创建所有的项目文档和计划。这不仅可以节省时间，还可以确保项目文档的准确性和一致性。
                                </p>
                                <p>最后，自定义项目模板还便于团队成员理解和使用。通过清晰的模板结构和内容，团队成员可以更快地了解项目的目标。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_showview2">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentfontText">
                            <div class="contentTitle">项目进度、计划实时展现</div>
                            <div class="describe">
                                <p>支持自定义化项目模板，可以有效提高项目管理的效率，确保质量、减少重复工作，并促进团队成员之间的理解和协作。</p>
                                <p>首先，通过预设的项目结构、任务和流程，团队可以更快地启动项目，减少</p>
                            </div>
                        </div>
                        <div class="contentfontPhoto">
                            <img src="../assets/picture/CustomWorkbench3.png" class="ProjectControlheadImg">
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_showview1">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentfontPhoto">
                            <img src="../assets/picture/CustomWorkbench4.png" class="ProjectControlheadImg">
                        </div>
                        <div class="contentfontText">
                            <div class="contentTitle">项目组织、权限配置更人性化</div>
                            <div class="describe">在组织管理层面上，项目组织作为非职能组织在项目创建时自动产生，有权限的项目成员可以在其基础上按照项目组织的分工进行细化。
                                每个项目组织均可以按照项目成员的分工配置其相应的权限，例如项目操作权限、文件访问权限、审批权限等，通过合理分配人力资源及其他资源，确保项目顺利进行。</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_showview2">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentfontText">
                            <div class="contentTitle">全方位协作，提高沟通效率</div>
                            <div class="describe">
                                <p>深度整合了百丝会议及百丝聊天系统，告别传统项目管理中的IM、电话、邮件、项目会议，通过项目群内嵌入所有项目信息，即使是新加入的项目成员在权限允许的条件下也能查看历史所有的信息，减少重复沟通的成本，提高成员工作效率。
                                </p>
                                <p>而深度集成的商务信息，项目成员在权限允许范围内能及时了解商务或销售部门与客户沟通的情况、项目合同，及时了解项目合同范围以及客户对项目的意见和建议，需求方面的变化等信息。
                                </p>
                            </div>
                        </div>
                        <div class="contentfontPhoto">
                            <img src="../assets/picture/CustomWorkbench5.png" class="ProjectControlheadImg">
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_showview1">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentfontPhoto">
                            <img src="../assets/picture/CustomWorkbench2.png" class="ProjectControlheadImg">
                        </div>
                        <div class="contentfontText">
                            <div class="contentTitle">高效、灵活的文档流转，更安全、更方便</div>
                            <div class="describe">
                                <p>项目文档自动存储在云盘应用空间，支持配置项目独立文档空间，按需要配置多维属性，让文件的检索更方便。而通过项目对文件的权限进行独立管控，使项目文档更安全。</p>
                                <p>全面支持拖拉操作，让操作更方便。并且每个文件均可以找到具体的来源，包括它是由哪个任务上传、产生的，跟哪些任务有关联关系等。</p>
                                <p>而安装了桌面版的文件上传插件，如果配置了项目关联硬盘目录，只要把文件复制或移动到相应目录，或在该目录修改了文件内容，文件上传插件会静默的帮您把文件更新到相应项目上，避免出现忘记上传或版本不一对致的情况发生。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Informationbar />
        <Footer />

    </div>
</template>

<script>
import TopNav from '../components/TopNav.vue';
import Informationbar from '../components/Informationbar.vue';
import Footer from '../components/Footer.vue';

import ElementUI from 'element-ui';
import Vue from 'vue';
Vue.use(ElementUI)

export default {
    components: { Footer, TopNav, Informationbar },
    data() {
        return {
            ProjectControlPainList: [
                {
                    ProjectControlPainItemtitle: '项目管理标准不统一',
                    ProjectControlIcon1: require("../assets/icon/ProjectControlIcon1.png")
                },
                {
                    ProjectControlPainItemtitle: '时间管理不清晰',
                    ProjectControlIcon1: require("../assets/icon/ProjectControlIcon2.png")
                },
                {
                    ProjectControlPainItemtitle: '任务分工不明确',
                    ProjectControlIcon1: require("../assets/icon/ProjectControlIcon3.png")
                },
                {
                    ProjectControlPainItemtitle: '成员沟通不畅通',
                    ProjectControlIcon1: require("../assets/icon/ProjectControlIcon4.png")
                },
                {
                    ProjectControlPainItemtitle: '需求变更频繁',
                    ProjectControlIcon1: require("../assets/icon/ProjectControlIcon5.png")
                }
            ],
            CarouselList1: [
                {
                    ImgPath: require("../assets/picture/Carousel1-1.png"),
                },
                {
                    ImgPath: require("../assets/picture/Carousel1-2.png"),
                },
                {
                    ImgPath: require("../assets/picture/Carousel1-3.png"),
                },
                {
                    ImgPath: require("../assets/picture/Carousel1-4.png"),
                },
            ]
        }
    }
}
</script>

<style lang="less" scoped>
.main {
    .section_1 {
        height: 514px;
        display: flex;
        justify-content: center;

        >.contentBox {
            width: 100%;
            max-width: 1920px;
            display: flex;
            justify-content: center;
            background-image: url(../assets/picture/CustomWorkbenchImg.png);

            >.contentfont {
                width: 1200px;
                display: flex;

                >.contentfontleft {
                    margin-top: 110px;
                    width: 526px;
                    margin: 110px 0 0 20px;

                    >.contentTitle {
                        font-size: 48px;
                        width: 432px;
                        font-weight: 700;
                        color: rgba(29, 33, 41, 1);
                    }

                    >.describe {
                        margin-top: 10px;
                        width: 463px;
                        font-size: 20px;
                        text-align: justify;
                        line-height: 28px;
                        color: rgba(29, 33, 41, 1);
                    }

                    >.ButtonBox {
                        margin-top: 50px;

                        >.registerButton {
                            width: 88px;
                            height: 36px;
                            opacity: 1;
                            color: rgba(54, 94, 255, 1);
                            border-radius: 4px;
                            border: 1px solid rgba(54, 94, 255, 1);
                            background-color: transparent;
                            font-size: 14px;
                            cursor: pointer;
                        }

                        >.loginButton {
                            width: 90px;
                            height: 36px;
                            border-radius: 4px;
                            background: rgba(54, 94, 255, 1);
                            border: 0px;
                            color: rgba(255, 255, 255, 1);
                            font-size: 14px;
                            justify-content: center
                        }
                    }

                }

                >.contentfontright {
                    .ProjectControldImg {
                        margin-top: 60px;
                    }
                }
            }
        }
    }

    .section_2 {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .section_showview1 {
        height: 554px;
        display: flex;
        justify-content: center;

        >.contentBox {
            width: 100%;
            max-width: 1920px;
            display: flex;
            justify-content: center;

            >.contentfont {
                width: 1200px;
                display: flex;
                align-items: center;

                >.contentfontText {
                    width: 485px;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;

                    >.contentTitle {
                        font-size: 24px;
                        font-weight: 700;
                    }

                    >.describe {
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(78, 89, 105, 1);
                        margin-top: 8px;
                        text-align: justify;
                    }
                }


                >.contentfontPhoto {
                    width: 714px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    >.Carousel {
                        display: flex;
                        overflow: hidden;
                        width: 634px;
                        animation: action 10s infinite;
                    }

                    >.ProjectControlheadImg {
                        height: 26px;
                        box-shadow: 0px 20px 20px rgba(29, 33, 41, 0.06);
                    }
                }
            }
        }
    }

    .section_showview2 {
        height: 554px;
        display: flex;
        justify-content: center;

        >.contentBox {
            width: 100%;
            max-width: 1920px;
            background: rgba(251, 251, 253, 1);
            display: flex;
            justify-content: center;

            >.contentfont {
                width: 1200px;
                display: flex;

                >.contentfontPhoto {
                    display: flex;
                    align-items: center;
                    margin-left: 80px;

                    >img {
                        height: 394px;
                        box-shadow: 0px 20px 20px rgba(29, 33, 41, 0.06);
                    }
                }

                >.contentfontText {
                    width: 485px;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;

                    >.contentTitle {
                        font-size: 24px;
                        font-weight: 700;
                    }

                    >.describe {
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(78, 89, 105, 1);
                        margin-top: 8px;
                    }
                }


            }
        }
    }

}


.ViewTitleBox {
    display: flex;
    justify-content: center;

    .ViewTitle {
        width: 100%;
        margin-top: 20px;
        max-width: 1920px;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
    }
}

.ProjectControlPain {
    width: 100%;
    max-width: 1920px;
    height: 375px;
    display: flex;
    align-items: center;
    justify-content: center;

    >.ProjectControlPainBox {
        width: 1200px;


        >.ProjectControlPainTitle {
            font-size: 40px;
            font-weight: 700;
            text-align: center;
            height: 108px;
        }

        >.ProjectControlPainList {
            height: 109px;
            display: flex;
            padding: 0 30px;
            justify-content: space-between;

            >.ProjectControlPainItem {
                text-align: center;

                >.ProjectControlPainItemtitle {
                    font-size: 20px;
                    font-weight: 400;
                    margin-top: 20px;
                }
            }
        }
    }
}

p {
    margin-top: 10px;
}
</style>
