<template>
    <div class="TopNav">
        <div class="NavBox">
            <img src="../assets/picture/logo.png">
            <div class="nav-font nav-fontFirst" v-popover:popover @mouseenter="NavDetailShow = 1"
                @mouseleave="NavDetailShow = 0">
                <span> 产品功能 </span>
                <img src="../assets/icon/zankai.png" class="zankai">
            </div>
            <!-- <div class="nav-font">
                <span> 客户案例 </span>
                <img src="../assets/icon/zankai.png" class="zankai">
            </div>
            <div class="nav-font">
                <span> 解决方案 </span>
                <img src="../assets/icon/zankai.png" class="zankai">
            </div> -->
            <div class="nav-font" v-popover:popover @mouseenter="NavDetailShow = 2" @mouseleave="NavDetailShow = 0">
                <span> 服务与支持 </span>
                <img src="../assets/icon/zankai.png" class="zankai">
            </div>
            <div class="nav-font">
                <span> 下载中心 </span>
                <img src="../assets/icon/zankai.png" class="zankai">
            </div>
            <!-- <div class="nav-font">
                <span> 会议 </span> -->
                <!-- <img src="../assets/icon/zankai.png" class="zankai"> -->
            <!-- </div>
            <div class="nav-font">
                <span> 聊天客服 </span> -->
                <!-- <img src="../assets/icon/zankai.png" class="zankai"> -->
            <!-- </div> -->
            <!-- <div class="nav-font">
                <span> 定价 </span>
                <img src="../assets/icon/zankai.png" class="zankai">
            </div> -->
            <div class="registerButton"
                onclick='window.open("https://saas.teamco.host:10998/saas/enterprise/#/register-index?loginTitle=Teamco&tenantVersionType=2")'>
                免费注册</div>
            <div class="loginButton" onclick='window.open("https://teamco.com.cn/saas/")'>登录</div>
            <div class="NavDetail"@mouseenter="NavDetailShow = 1" v-if="NavDetailShow === 1" 
                @mouseleave="NavDetailShow = 0">
                <div class="NavDetailselect">
                    <template v-for="(item, index) in NavDetailselectList">
                        <div class="selectBox" @mouseenter="Hover = index">
                            <div class="selectBoxtitle"
                                :style="index === Hover ? 'font-weight: 500;' : 'font-weight: 400;'">{{ item }}
                            </div>
                            <img src="../assets/icon/juxing.png"
                                :style="index === Hover ? 'display: inline-block;' : 'display: none;'">
                        </div>
                    </template>
                </div>
                <div class="Basic" >
                    <div class="row1">
                        <div class="routerBox" v-for="item in row1">
                            <div class="routerTitle">
                                <router-link :to="item.path" tag="div">
                                    {{ item.routerTitle }}
                                </router-link>
                            </div>
                            <div class="routerDescribe">{{ item.routerDescribe }}</div>
                        </div>
                    </div>
                    <div class="row2">
                        <div class="routerBox" v-for="item in row2">
                            <div class="routerTitle">
                                <router-link :to="item.path" tag="div">
                                    {{ item.routerTitle }}
                                </router-link>
                            </div>
                            <div class="routerDescribe">{{ item.routerDescribe }}</div>
                        </div>
                    </div>
                    <div class="row3">
                        <div class="routerBox" v-for="item in row3">
                            <div class="routerTitle">
                                <router-link :to="item.path" tag="div">
                                    {{ item.routerTitle }}
                                </router-link>
                            </div>
                            <div class="routerDescribe">{{ item.routerDescribe }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="NavService" v-if="NavDetailShow === 2" @mouseenter="NavDetailShow = 2"
                @mouseleave="NavDetailShow = 0">
                <!-- <div >教学教程</div> -->
                <div class="NavServicefont" v-for="item in NavServiceList"><router-link :to="item.path" tag="div">{{
                    item.Title
                }}</router-link></div>
                <!-- <div class="NavServicefont">联系我们</div> -->
                <!-- <div>更新日志</div> -->
            </div>
        </div>
    </div>
</template>

<script>
import ElementUI from 'element-ui';
import Vue from 'vue';
import { BasicFeatures, ProjectManage, ResearchManage, CRMManage, OrganizationManage, OAApproval, ConferenceSystem, ChatSystem, EnterpriseCloudrives, ManageSystem } from '../Data/NavDetailData'
Vue.use(ElementUI)

export default {
    data() {
        return {
            Hover: 0, visible: false, NavDetailShow: 0, BasicFeatures, ProjectManage, ResearchManage, CRMManage, OrganizationManage, OAApproval, ConferenceSystem, ChatSystem, EnterpriseCloudrives, ManageSystem,
            NavDetailselectList: ['基础功能', '项目管理', '研发管理', 'CRM管理', '组织管理', 'OA审批', '会议系统', '聊天系统', '企业云盘', '管理后台',],
            row1: BasicFeatures[0], row2: BasicFeatures[1], row3: BasicFeatures[2],
            NavServiceList: [
                {
                    Title: '帮助手册',
                    path: '/'
                },
                {
                    Title: '联系我们',
                    path: '/'
                },
            ]
        };
    },
    watch: {
        Hover(newVal, oldVal) {
            if( newVal === 0 ) {
                this.row1 = BasicFeatures[0],
                this.row2 = BasicFeatures[1],
                this.row3 = BasicFeatures[2]
            }else if( newVal === 1 ){
                this.row1 = ProjectManage[0],
                this.row2 = ProjectManage[1],
                this.row3 = ProjectManage[2]
            }else if( newVal === 2 ){
                this.row1 = ResearchManage[0],
                this.row2 = ResearchManage[1],
                this.row3 = ResearchManage[2]
            }else if( newVal === 3 ){
                this.row1 = CRMManage[0],
                this.row2 = CRMManage[1],
                this.row3 = CRMManage[2]
            }else if( newVal === 4 ){
                this.row1 = OrganizationManage[0],
                this.row2 = OrganizationManage[1],
                this.row3 = OrganizationManage[2]
            }else if( newVal === 5 ){
                this.row1 = OAApproval[0],
                this.row2 = OAApproval[1],
                this.row3 = OAApproval[2]
            }else if( newVal === 6 ){
                this.row1 = ConferenceSystem[0],
                this.row2 = ConferenceSystem[1],
                this.row3 = ConferenceSystem[2]
            }else if( newVal === 7 ){
                this.row1 = ChatSystem[0],
                this.row2 = ChatSystem[1],
                this.row3 = ChatSystem[2]
            }else if( newVal === 8 ){
                this.row1 = EnterpriseCloudrives[0],
                this.row2 = EnterpriseCloudrives[1],
                this.row3 = EnterpriseCloudrives[2]
            }else if( newVal === 9 ){
                this.row1 = ManageSystem[0],
                this.row2 = ManageSystem[1],
                this.row3 = ManageSystem[2]
            }
        }
    },
    mounted() {
        const nav = document.querySelector('.TopNav');
        const navTop = nav.offsetTop;
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > navTop) {
                nav.style.position = 'fixed';
                nav.style.top = '0px';
                nav.style.boxShadow = '0px 6px 7px rgba(217, 217, 217, 0.25)';
                nav.style.zIndex = 9999;
            } else {
                nav.style.position = 'static'
                nav.style.top = '';
                nav.style.boxShadow = '';
            }
        })
    },
}
</script>

<style lang="less">
.TopNav {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 1);

    >.NavBox {
        width: 1200px;
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: center;

        >.nav-font {
            margin-left: 39px;
            display: flex;
            align-items: center;
            height: 60px;

            >.zankai {
                margin-left: 5px;
            }
        }

        >.nav-fontFirst {
            margin-left: 87px;

        }


        >.registerButton {
            width: 88px;
            height: 34px;
            opacity: 1;
            color: rgba(54, 94, 255, 1);
            border-radius: 4px;
            border: 1px solid rgba(54, 94, 255, 1);
            background-color: transparent;
            font-size: 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 18px 0 auto;
        }

        >.loginButton {
            width: 90px;
            height: 36px;
            border-radius: 4px;
            background: rgba(54, 94, 255, 1);
            border: 0px;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center
        }
    }

}

.NavService {
    width: 56px;
    height: 80px;
    border-radius: 4px;
    box-shadow: 0px 10px 30px rgba(29, 33, 41, 0.06);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: rgba(255, 255, 255, 1);
    position: absolute;
    padding: 10px 20px;
    top: 60px;
    left: 325px;

    .NavServicefont {
        color: rgba(78, 89, 105, 1);
        font-size: 14px;
        line-height: 40px;
        font-weight: 400;
        cursor: pointer;

        &:hover {
            color: rgba(54, 94, 255, 1);
        }
    }
}

.NavDetail {
    width: 994px;
    height: 416px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 10px 30px rgba(29, 33, 41, 0.06);
    padding: 20px 0 24px 24px;
    display: flex;
    position: absolute;
    top: 50px;
    left: 55px;

    >.NavDetailselect {
        width: 130px;


        >.selectBox {
            height: 25px;
            display: grid;


            &:not(:first-child) {
                margin-top: 14px;
            }

            >.selectBoxtitle {
                font-size: 14px;
                color: rgba(78, 89, 105, 1);
            }
        }
    }

    .Basic {
        display: flex;

        >.row1 {
            width: 276px;

        }

        >.row2 {
            width: 312px;
        }
    }
}

.routerBox {
    height: 80px;
    width: 250px;

    >.routerTitle {
        font-size: 14px;
        font-weight: 500;
        color: rgba(29, 33, 41, 1);
        cursor: pointer;
    }

    >.routerDescribe {
        color: rgba(78, 89, 105, 1);
        font-size: 12px;
        font-weight: 400;
    }
}
</style>