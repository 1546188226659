<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import QianYiHomePage from './views/QianYiHomePage.vue';

export default {
  components: { QianYiHomePage }
}
</script>

<style>
#app {}
</style>
