<template>
    <div class="ContactUs">
        <TopNav/>
        <Search/>
        <div class="main">
            
        </div>
        <Informationbar/>
        <Footer/>
    </div>
</template>

<script>
import TopNav from '../components/TopNav.vue';
import Search from '../components/Search.vue';
import Informationbar from '../components/Informationbar.vue';
import Footer from '../components/Footer.vue';

export default {
    components: { Footer, Search, TopNav, Informationbar }
}
</script>

<style lang="less" scoped>
</style>