<template>
    <div class="QianYiHomePage">
        <TopNav />
        <div class="main">
            <!-- 第一板块 -->
            <div class="section_1">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentTitle">高效、便捷、一站式办公协同</div>
                        <div class="describe">
                            数字化时代，高效协同办公是企业提升竞争力的关键。千蚁办公协同平台，为您打造一站式的项目管理、客户管理、合同管理、应收账款、云存储、会议沟通、聊天交流等全方位解决方案，提升工作效率。
                        </div>
                        <div class="ButtonBox">
                            <button class="registerButton"
                                onclick='window.open("https://saas.teamco.host:10998/saas/enterprise/#/register-index?loginTitle=Teamco&tenantVersionType=2")'>免费注册</button>
                            <button class="loginButton" style="margin-left: 18px;">预约演示</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 第二板块 -->
            <div class="section_2">
                <div class="contentBox_2">
                    <div class="contentfont_2">
                        <div class="contentTitle_2">一站式企业协同效能平台，覆盖企业常见协作场景</div>
                        <div class="describe_2">模板化事项管理，覆盖不同行业业务需求</div>
                        <div class="CarouselBox">
                            <template v-for="(item, index) in CarouselSelectList" >
                                <div class="CarouselSelect" @click="selected = index"
                                    :style="index === selected ? 'border-bottom-color: rgba(54, 94, 255, 1)' : 'border-bottom-color: rgba(229, 230, 235, 1)'">
                                    <img class="CarouselSelect_Img" :src="item.CarouselSelect_Img">
                                    <div class="CarouselSelect_title">{{ item.CarouselSelect_title }}</div>
                                </div>
                            </template>
                        </div>
                        <div class="Carouselcontent">
                            <template v-for="(item, index) in CarouselcontentBoxList">
                                <div class="CarouselcontentBox" v-if="index === selected">
                                    <div class="CarouselcontentTitle">{{ item.CarouselcontentTitle }}</div>
                                    <div class="CarouselcontentDescribe">{{ item.CarouselcontentDescribe }}</div>
                                    <div class="Knowbutton">
                                        <span class="KnowbuttonTitle">了解更多</span>
                                        <img src="../assets/icon/knowmore.png" class="KnowbuttonImg">
                                    </div>
                                </div>
                                <img :src="item.CarouselcontentImg" class="CarouselcontentImg"  v-if="index === selected">
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 第三板块 -->
            <div class="section_3">
                <div class="contentBox_3">
                    <div class="contentfont_3">
                        <div class="contentTitle_3">
                            <div class="Bigtitle">高效协同，一应俱全，开箱即用</div>
                            <div class="Smalltitle">设计简洁优雅，颠覆传统工具体验</div>
                        </div>
                        <div class="ToolsBox">
                            <div class="Tools" v-for="(item, index) in ToolsList">
                                <div class="Toolstop">
                                    <div class="Toolleft">
                                        <div class="Tooltitle">{{ item.ToolTitle }}</div>
                                        <img src="../assets/icon/icon0.png">
                                    </div>
                                    <div class="Toolright">
                                        <img :src="item.ToolImg">
                                    </div>
                                </div>
                                <div class="Toolsbottom">{{ item.ToolDescribe }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Informationbar />
        <Footer />
    </div>
</template>

<script>
import TopNav from '../components/TopNav.vue';
import Footer from '../components/Footer.vue';
import Informationbar from '../components/Informationbar.vue';

export default {
    components: { TopNav, Footer, Informationbar },
    data() {
        return {
            selected: 0,
            CarouselSelectList: [
                {
                    CarouselSelect_title: '一站式协同',
                    CarouselSelect_Img: require('../assets/icon/tubiao1.png')
                },
                {
                    CarouselSelect_title: '有始有终',
                    CarouselSelect_Img: require('../assets/icon/tubiao2.png')
                },
                {
                    CarouselSelect_title: '效能绩效',
                    CarouselSelect_Img: require('../assets/icon/tubiao3.png')
                },
                {
                    CarouselSelect_title: '知识沉淀',
                    CarouselSelect_Img: require('../assets/icon/tubiao4.png')
                },
                {
                    CarouselSelect_title: '把控全局',
                    CarouselSelect_Img: require('../assets/icon/tubiao5.png')
                }
            ],
            CarouselcontentBoxList: [
                {
                    CarouselcontentTitle: '一站式协同',
                    CarouselcontentDescribe: '基于工作场景的云存储、即时通讯及会议系统各，项目、任务、商机、合同、应收、回款、审批全流程协同。',
                    CarouselcontentImg: require('../assets/picture/showview1.png')
                },
                {
                    CarouselcontentTitle: '有始有终',
                    CarouselcontentDescribe: '从目标到项目，从线索到回款，从任务到验收，全程数字化跟进，每个环节均可审、可查，真正让工作件件有着落，事事有回音。',
                    CarouselcontentImg: require('../assets/picture/showview2.png')
                },
                {
                    CarouselcontentTitle: '效能绩效',
                    CarouselcontentDescribe: '建立可量化的评价标准，数据化呈现工作成效，凝聚核心人才。',
                    CarouselcontentImg: require('../assets/picture/showview3.png')
                },
                {
                    CarouselcontentTitle: '知识沉淀',
                    CarouselcontentDescribe: '零散文件统一沉淀，建立有序沉淀的企业智库，驱动企业创新增长。',
                    CarouselcontentImg: require('../assets/picture/showview4.png')
                },
                {
                    CarouselcontentTitle: '把控全局',
                    CarouselcontentDescribe: '信息云端同步，一站式待办管理中心，高效协作同步进展，快捷指派落实到人。',
                    CarouselcontentImg: require('../assets/picture/showview5.png')
                },
            ],
            ToolsList: [
                {
                    ToolTitle: '项目管理',
                    ToolDescribe: '轻松创建、分配、追踪和管理项目任务。通过实时更新任务进度、提醒功能以及团队成员间的协作沟通，确保项目按时按质完成，提高项目成功率。',
                    ToolImg: require('../assets/icon/icon1.png')
                },
                {
                    ToolTitle: '研发管理',
                    ToolDescribe: '无论是标准的敏捷研发管理，还是传统的瀑布式开发， 都能提供强大的工具支持。',
                    ToolImg: require('../assets/icon/icon2.png')
                },
                {
                    ToolTitle: 'CRM',
                    ToolDescribe: '将线索，客户，商机，合同，项目，应收，联系等环节信息数据紧密融合，实现完整闭环信息化管理。',
                    ToolImg: require('../assets/icon/icon3.png')
                },
                {
                    ToolTitle: '审批中心',
                    ToolDescribe: '图形化自定义表单及流程编辑器，轻松创建人事、行政、财务等各类管理流程，高效规范办事。',
                    ToolImg: require('../assets/icon/icon4.png')
                },
                {
                    ToolTitle: '组织管理',
                    ToolDescribe: '支持智能组织及非智能组织的管理，汇集组织各类事项及统计、成员日志等数据，使责权更明确，协作更方便，管理更精细及高效。',
                    ToolImg: require('../assets/icon/icon5.png')
                },
                {
                    ToolTitle: '自定义工作台',
                    ToolDescribe: '基于最前端的微前端技术，可以实现在一个界面上整合内部及外部应用，满足所有办公需求！便捷操作，提升团队生产力！',
                    ToolImg: require('../assets/icon/icon6.png')
                },
                {
                    ToolTitle: '会议系统',
                    ToolDescribe: '支持在线会议功能，让团队成员可以随时随地参与讨论和协作。通过共享屏幕、实时聊天、会议录制等功能，提高会议效率，确保信息传递准确无误。同时支持多人同时共享，会议主持人根据会议实际情况实时调控。',
                    ToolImg: require('../assets/icon/icon7.png')
                },
                {
                    ToolTitle: '企业云盘',
                    ToolDescribe: '安全高速的上传、下载及预览体验，帮助企业整理资料、沉淀经验、共享知识。多重备份和加密技术，确保文件数据的安全性和可靠性。',
                    ToolImg: require('../assets/icon/icon8.png')
                },
                {
                    ToolTitle: '即时聊天',
                    ToolDescribe: '内置即时聊天工具，支持一对一或群组聊天，方便团队成员之间快速沟通、分享文件和信息。同时，聊天记录可长期保存，方便随时查阅。',
                    ToolImg: require('../assets/icon/icon9.png')
                },
            ]
        }
    },
    methods: {
        Readyselect(index) {
            this.selected = index
        }
    },
    mounted() {
        // 设置定时器，3秒后执行一次操作
        this.timer = setInterval(() => {

            if (this.selected < 4) {
                this.selected = this.selected + 1
            } else {
                this.selected = 0
            }
        }, 3000);
    },
    beforeDestroy() {
        clearInterval(this.timer); // 清除定时器
    },
}
</script>

<style lang="less" scoped>
.section_1 {
    height: 600px;
    display: flex;
    justify-content: center;
}

.contentBox {
    width: 100%;
    max-width: 1920px;
    display: flex;
    justify-content: center;
    background-image: url(../assets/picture/backgroundimg1.png);

    >.contentfont {
        padding-top: 120px;
        width: 1200px;

        >.contentTitle {
            font-size: 52px;
            font-weight: 700;
            color: rgba(54, 94, 255, 1);
        }

        >.describe {
            margin-top: 10px;
            font-size: 20px;
            width: 682px;
            line-height: 28px;
            color: rgba(54, 94, 255, 1);
        }

        >.ButtonBox {
            margin-top: 28px;

            >.registerButton {
                width: 88px;
                height: 36px;
                opacity: 1;
                color: rgba(54, 94, 255, 1);
                border-radius: 4px;
                border: 1px solid rgba(54, 94, 255, 1);
                background-color: transparent;
                font-size: 14px;
                cursor: pointer;
            }

            >.loginButton {
                width: 90px;
                height: 36px;
                border-radius: 4px;
                background: rgba(54, 94, 255, 1);
                border: 0px;
                color: rgba(255, 255, 255, 1);
                font-size: 14px;
                justify-content: center
            }
        }
    }
}

.section_2 {
    height: 900px;
    display: flex;
    justify-content: center;
}

.contentBox_2 {
    width: 100%;
    max-width: 1920px;
    display: flex;
    justify-content: center;
    background-image: url(../assets/picture/backgroundimg2.png);

    >.contentfont_2 {
        padding-top: 80px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 1200px;

        >.contentTitle_2 {
            color: rgba(29, 33, 41, 1);
            height: 65px;
            font-size: 40px;
            font-weight: 700;
        }

        >.describe_2 {
            font-size: 20px;
            color: rgba(78, 89, 105, 1);
            height: 72px;
        }

        >.CarouselBox {
            width: 100%;
            height: 180px;
            display: flex;

            >.CarouselSelect {
                width: 240px;
                height: 145px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                border-bottom: 4px solid rgb(229, 230, 235);

                >.CarouselSelect_title {
                    width: 100%;
                    font-size: 20px;
                    text-align: center;
                }

                >.CarouselSelect_Img {
                    height: 63px;
                }
            }
        }

        >.Carouselcontent {
            display: flex;
            height: 100%;
            width: 100%;

            >.CarouselcontentBox {
                padding: 45px 0 0 20px;
                width: 100%;

                >.CarouselcontentTitle {
                    font-size: 24px;
                    font-weight: 700;
                }

                >.CarouselcontentDescribe {
                    margin-top: 18px;
                    width: 400px;
                    text-align: justify;
                    color: rgba(78, 89, 105, 1);
                }

                >.Knowbutton {
                    width: 76px;
                    height: 26px;
                    border-radius: 100px;
                    font-size: 12px;
                    cursor: pointer;
                    background: rgba(54, 94, 255, 1);
                    color: rgba(255, 255, 255, 1);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                }
            }

            >.CarouselcontentImg {
                height: 422px;
                box-shadow: 0px 20px 20px rgba(29, 33, 41, 0.06);
            }
        }
    }
}

.section_3 {
    display: flex;
    justify-content: center;
}

.contentBox_3 {
    background-image: url(../assets/picture/backgroundimg3.png);
    width: 100%;
    max-width: 1920px;
    display: flex;
    justify-content: center;
    height: 1092px;

    >.contentfont_3 {
        width: 1200px;

        >.contentTitle_3 {
            height: 133px;

            >.Bigtitle {
                color: rgba(29, 33, 41, 1);
                font-size: 40px;
                font-weight: 700;
                margin-top: 80px;
                text-align: center;
            }

            >.Smalltitle {
                color: rgba(78, 89, 105, 1);
                font-size: 20px;
                margin-top: 10px;
                text-align: center;
            }
        }

        >.ToolsBox {
            height: 800px;
            width: 1200px;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            >.Tools {
                width: 386px;
                height: 253px;
                border-radius: 4px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 20px rgba(29, 33, 41, 0.06);

                >.Toolstop {
                    display: flex;
                    padding: 30px 36px 0;
                    height: 87px;

                    >.Toolleft {
                        >.Tooltitle {
                            font-size: 24px;
                            font-weight: 500;
                        }
                    }

                    >.Toolright {
                        margin-left: auto;
                    }
                }

                >.Toolsbottom {
                    padding: 0 36px;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 22px;
                    color: rgba(78, 89, 105, 1);
                    text-align: justify;
                }
            }
        }
    }
}
</style>