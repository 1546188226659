import Vue from 'vue';
import Router from 'vue-router';
import App from '../App.vue';
import QianYiHomePage from '../views/QianYiHomePage.vue';
import CustomWorkbench from '../views/CustomWorkbench.vue';
import PendingWorkbench from '../views/PendingWorkbench.vue';
import ViewsEditors from '../views/ViewsEditors.vue'
import ProjectControl from '../views/ProjectControl.vue';
import VideoDetail from '../views/VideoDetail.vue';
import ContactUs from '../views/ContactUs.vue';

Vue.use(Router);

export default new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'home',
      component: QianYiHomePage
    },
    {
      path: '/CustomWorkbench',
      name: 'CustomWorkbench',
      component: CustomWorkbench
    },
    {
      path: '/PendingWorkbench',
      name: 'PendingWorkbench',
      component: PendingWorkbench
    },
    {
      path: '/ViewsEditors',
      name: 'ViewsEditors',
      component: ViewsEditors
    },
    {
      path: '/ProjectControl',
      name: 'ProjectControl',
      component: ProjectControl
    },
    {
      path: '/VideoDetail',
      name: 'VideoDetail',
      component: VideoDetail
    },
    {
      path: '/ContactUs',
      name: 'ContactUs',
      component: ContactUs
    }
  ]
});