<template>
    <div class="CustomWorkbench">
        <TopNav />
        <div class="main">
            <div class="section_1">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentfontleft">
                            <div class="contentTitle">自定义工作台</div>
                            <div class="describe">自由配置工作台，让工作更高效</div>
                            <div class="ButtonBox">
                                <button class="registerButton"
                                    onclick='window.open("https://saas.teamco.host:10998/saas/enterprise/#/register-index?loginTitle=Teamco&tenantVersionType=2")'>免费注册</button>
                                <button class="loginButton" style="margin-left: 18px;">预约演示</button>
                            </div>
                        </div>
                        <div class="contentfontright">
                            <img src="../assets/picture/CustomWorkbench1.png" class="CustomWorkbench1">
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_showview1">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentfontPhoto">
                            <img src="../assets/picture/CustomWorkbench2.png" class="CustomWorkbench1">
                        </div>
                        <div class="contentfontText">
                            <div class="contentTitle">按需定制，灵活构建</div>
                            <div class="describe">所有内容均可以根据需要定制在一个界面上，让不同的工作无需来回切换，提高工作效率。</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_showview2">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentfontText">
                            <div class="contentTitle">多种设置方式</div>
                            <div class="describe">支持自由摆放，两宫格、四宫格等预设模式，同时提供自适配，设置更方便，界面更整洁。</div>
                        </div>
                        <div class="contentfontPhoto">
                            <img src="../assets/picture/CustomWorkbench3.png" class="CustomWorkbench1">
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_showview1">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentfontPhoto">
                            <img src="../assets/picture/CustomWorkbench4.png" class="CustomWorkbench1">
                        </div>
                        <div class="contentfontText">
                            <div class="contentTitle">自由拖拽</div>
                            <div class="describe">支持自由拖拽方式排布界面，排布更自由。同时自动识别碰撞处理，即使是放错了，也不会出现重叠的情况，导致查看不方便。</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_showview2">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentfontText">
                            <div class="contentTitle">释放专业生产力</div>
                            <div class="describe">简单配置即可灵活使用，支持配置多个不同的工作台，为后续的处理节省更多折时间，释放专业生产力。</div>
                        </div>
                        <div class="contentfontPhoto">
                            <img src="../assets/picture/CustomWorkbench5.png" class="CustomWorkbench1">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Informationbar />
        <Footer />

    </div>
</template>

<script>
import TopNav from '../components/TopNav.vue';
import Informationbar from '../components/Informationbar.vue';
import Footer from '../components/Footer.vue';

export default {
    components: { Footer, TopNav, Informationbar }
}
</script>

<style lang="less" scoped>
.main {
    .section_1 {
        height: 514px;
        display: flex;
        justify-content: center;

        >.contentBox {
            width: 100%;
            max-width: 1920px;
            display: flex;
            justify-content: center;
            background-image: url(../assets/picture/CustomWorkbenchImg.png);

            >.contentfont {
                width: 1200px;
                display: flex;

                >.contentfontleft {
                    margin-top: 110px;
                    width: 526px;
                    margin: 110px 0 0 20px;

                    >.contentTitle {
                        font-size: 48px;
                        font-weight: 700;
                        color: rgba(29, 33, 41, 1);
                    }

                    >.describe {
                        margin-top: 10px;
                        font-size: 20px;
                        width: 682px;
                        line-height: 28px;
                        color: rgba(29, 33, 41, 1);
                    }

                    >.ButtonBox {
                        margin-top: 28px;

                        >.registerButton {
                            width: 88px;
                            height: 36px;
                            opacity: 1;
                            color: rgba(54, 94, 255, 1);
                            border-radius: 4px;
                            border: 1px solid rgba(54, 94, 255, 1);
                            background-color: transparent;
                            font-size: 14px;
                            cursor: pointer;
                        }

                        >.loginButton {
                            width: 90px;
                            height: 36px;
                            border-radius: 4px;
                            background: rgba(54, 94, 255, 1);
                            border: 0px;
                            color: rgba(255, 255, 255, 1);
                            font-size: 14px;
                            justify-content: center
                        }
                    }

                }

                >.contentfontright {
                    .CustomWorkbench1 {
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    .section_showview1 {
        height: 554px;
        display: flex;
        justify-content: center;

        >.contentBox {
            width: 100%;
            max-width: 1920px;
            display: flex;
            justify-content: center;

            >.contentfont {
                width: 1200px;
                display: flex;

                >.contentfontText {
                    width: 485px;
                    >.contentTitle {
                        font-size: 24px;
                        font-weight: 700;
                        margin-top: 232px;
                    }
                    >.describe {
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(78, 89, 105, 1);
                        margin-top: 8px;
                    }
                }


                >.contentfontPhoto {
                    width: 714px;
                    display: flex;
                    align-items: center;

                    >img {
                        height: 394px;
                        box-shadow: 0px 8px 30px  rgba(29, 33, 41, 0.06);
                    }
                }
            }
        }
    }
    .section_showview2 {
        height: 554px;
        display: flex;
        justify-content: center;

        >.contentBox {
            width: 100%;
            max-width: 1920px;
            background: rgba(251, 251, 253, 1);
            display: flex;
            justify-content: center;

            >.contentfont {
                width: 1200px;
                display: flex;
                
                >.contentfontPhoto {
                    display: flex;
                    align-items: center;
                    margin-left: 80px;
    
                    >img {
                        height: 394px;
                        box-shadow: 0px 8px 30px  rgba(29, 33, 41, 0.06);
                    }
                }
                >.contentfontText {
                    width: 485px;
                    >.contentTitle {
                        font-size: 24px;
                        font-weight: 700;
                        margin-top: 232px;
                    }
                    >.describe {
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(78, 89, 105, 1);
                        margin-top: 8px;
                    }
                }


            }
        }
    }


}
</style>
